/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import CookieConsent from 'react-cookie-consent'

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <Header siteTitle={data.site.siteMetadata?.title || 'Title'} />
            <div
                style={{
                    margin: '0 auto',
                }}
            >
                <main>{children}</main>
                <Footer/>
                <CookieConsent
                    location="bottom"
                    enableDeclineButton flipButtons
                    buttonText="Accept"
                    declineButtonText="Decline"
                    cookieName="gatsby-gdpr-google-analytics"
                    expires={999} overlay
                    style={{
                        background: 'white',
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                        textAlign: 'center',
                    }}
                >
                    <span className="text-xs sm:text-sm text-mainColor">This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.
We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website.
If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.</span>
                </CookieConsent>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
